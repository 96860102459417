.backing {
    visibility: hidden;
    position: fixed;
    left:0;
    width: 100vw;
    height: 100vh;
}

.backing.reveal {
    background-color: #111a;
    color: white;
    z-index: 1000;
    animation: reveal .05s linear 3s both;
}

.reveal.quick{
    animation: reveal .25s linear 0s both;
}
.reveal.quick .plaque{
    animation: reveal .25s linear 0s both;
    box-shadow: none;
    padding: 30px 0;
}

.reveal .plaque {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    max-height: 90vh;

    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 150px #444;
    background-color: #2F3335;
    border-radius: 15px;

    z-index: 1100;

    animation: reveal .5s linear 3.25s both;
    transition: box-shadow 4s 3.7s;
}

.history {
    background-color: #424247;
    margin: 10px auto;
    width: 230px;
    height: 530px;
    padding: 20px;
    white-space: pre-line;
    overflow-y: scroll;

}

.bottom{
    position: absolute;
    left:0;
    right:0;
    margin: auto;

    bottom: 20px;
}

.bottom .button{
    margin: 35px auto;
}

@keyframes reveal {
    from {
        visibility: visible;
        opacity: 0;
    }

    to {
        visibility: visible;
        opacity: 1;
    }
}