:root {
    --background: #1f1e20;
    --font-color: white;

    --tileColor: #464A4B;
    --green: #5dd384;
    --blue: #5399e9;
    --yellow: #e6c65d;
    --red: #d64f4f;
}

