.App{
  margin-top: 50px;
}

#big {
  --size: 140px !important;
  background-color: var(--color);
  animation: spiiin 5s linear  infinite;
  animation-delay: calc(var(--rand) + 1s);
}

@media screen and (max-width: 550px) {
  #big {
    --size: 13vw !important;
  }
}

@media screen and (min-width: 551px) and (max-width: 999px) {
  #big {
    --size: 12vw !important;
  }
}

@keyframes spiiin {
  0%,10%,20% {
    transform: rotateX(0);
  }

  5%,15%{
    transform: rotateX(90deg);
  }
}

