

.msg{
    position: fixed;
    left: 50%;
    top:15vh;
    translate: -50%;

    width: 50%;
    
    background-color: var(--tileColor);
    color: white;
    border: white 2px solid;
    border-radius: 25px;

    text-align: center;

    z-index: 5000;

    opacity: 0;
    visibility: hidden;
}

#reveal{
    animation: ghost 3s ease-in-out;
    
}

@keyframes ghost {
    0%, 100%{
        opacity: 0;
        visibility: visible;
    }

    15%, 85%{
        opacity: .9;
        visibility: visible;
    }

}